import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { LAO_TV_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
const { useBreakpoint } = Grid;

const color = "#011b5c";
const warper_bg = "white";
const color_bg = "white";
export default function LaoTvPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: LAO_TV_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: LAO_TV_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "ລາວທີວີ JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div className="border-y-[15px] border-[#011b5c] border-solid">
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/lao-tv/lao-tv-icon.png")} />
            </Helmet>
            <div className="border-y-[5px] border-[#b00000] border-solid">
                {(loading || loadingHistory) && (
                    <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                        <LoadingOutlined
                            style={{
                                color: "#fff",
                                fontSize: 40,
                            }}
                            spin
                        />

                        <div className="pl-4 text-sky-50">Loading ...</div>
                    </div>
                )}

                <Container className={"relative items-center flex flex-col py-[10px]"}>
                    <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                    {/* header logo */}
                    <Flex justify="center">
                        <Image
                            src={require("../../assets/images/lao-tv/lao-tv-header-2.png")}
                            rootClassName="max-w-[1024px]"
                            style={{ width: 1024 }}
                            preview={false}
                        />
                    </Flex>
                    {/* line */}
                    <Flex
                        vertical
                        style={{
                            background: "white",
                            backgroundPosition: "bottom",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            maxWidth: 1024,
                            width: "100%",
                        }}
                    >
                        <div className="pt-5">
                            <Flex gap={10} vertical={!screens.md}>
                                <Flex className="w-full order-0 md:order-1 sm:mx-0" vertical>
                                    <div className="md:hidden block">
                                        <FindResult
                                            date={rewardDetail?.roundName}
                                            rewardData={data}
                                            color={color}
                                            bgColor={color_bg}
                                        />
                                    </div>
                                    {/* content result */}
                                    <ResultLottery
                                        status={statusReward}
                                        data={data}
                                        date={rewardDetail?.roundName}
                                        color={color}
                                        bgColor={color_bg}
                                    />

                                    {/* history */}
                                    <div className="mt-[-24px]">
                                        <HistoryResult data={historyData} color={color} bgColor={color_bg} />
                                    </div>
                                </Flex>

                                <Flex className="w-full md:max-w-[330px] order-1 md:order-0" vertical>
                                    <div className="md:block hidden">
                                        <FindResult
                                            date={rewardDetail?.roundName}
                                            rewardData={data}
                                            color={color}
                                            bgColor={color_bg}
                                        />
                                    </div>
                                    <Flex vertical gap={10} className="px-0">
                                        <Image
                                            src={require("../../assets/images/lao-tv/lao-tv-1-1.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                        <Image
                                            src={require("../../assets/images/lao-tv/lao-tv-1-2.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                        <Image
                                            src={require("../../assets/images/lao-tv/lao-tv-1-3.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                        <Image
                                            src={require("../../assets/images/lao-tv/lao-tv-1-4.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </div>
                    </Flex>
                </Container>
            </div>
        </div>
    );
}
