import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Warper from "../../assets/images/minhngoctv/minhngoctv-bg.png";
import FindResultHeader from "../../assets/images/minhngoctv/minhngoctv-find-result-header.png";
import WarperResult from "../../assets/images/minhngoctv/minhngoctv-result-bg.png";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { MINHNGOC_TV_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const { useBreakpoint } = Grid;

const color = "#e80000";
const warper_bg = "white";
const color_bg = "white";
export default function MinhngocTvPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: MINHNGOC_TV_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: MINHNGOC_TV_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "Hà Nội Tv JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/minhngoctv/minhngoctv-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex
                    align="center"
                    style={{
                        backgroundImage: `url(${Warper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Image
                        src={require("../../assets/images/minhngoctv/minhngoctv-header-2.png")}
                        preview={false}
                        className="!h-[80%]"
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "white",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                    }}
                >
                    <div className="pt-5">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="hidden md:block md:w-full md:max-w-[200px] order-1 md:order-0" vertical gap={10}>
                                <Image
                                    src={require("../../assets/images/minhngoctv/minhngoctv-1.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/minhngoctv/minhngoctv-2.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/minhngoctv/minhngoctv-3.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                            </Flex>
                            <Flex className="w-full order-0 md:order-1 sm:mx-0" vertical>
                                <Flex vertical gap={10} className="mb-3 px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-4.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-find-result.png")}
                                        rootClassName="block md:hidden"
                                        preview={false}
                                    />
                                </Flex>
                                <div className="md:hidden block">
                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                        lang="VIM"
                                    />
                                </div>

                                <div className="px-3 sm:px-0">
                                    <Flex
                                        style={{
                                            backgroundImage: `url(${WarperResult})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "100% 100%",
                                            width: "100%",
                                            height: 45,
                                            fontSize: 24,
                                            color: color,
                                        }}
                                        justify="center"
                                        align="center"
                                    >
                                        <Image src={require("../../assets/images/minhngoctv/minhngoctv-5.png")} preview={false} />
                                        {rewardDetail?.roundName}
                                    </Flex>
                                </div>
                                {/* content result */}
                                <ResultLottery
                                    status={statusReward}
                                    data={data}
                                    date={rewardDetail?.roundName}
                                    color={color}
                                    bgColor={color_bg}
                                    lang="VIM"
                                />
                                {/* history */}
                                <div className="my-0">
                                    <HistoryResult data={historyData} color={color} bgColor={color_bg} lang="VIM" />
                                </div>
                                <div className="mt-[-10px] mb-2 px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-result-footer.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </div>
                                <Flex vertical gap={10} className="px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-6.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-7.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>

                            <Flex className="w-full md:max-w-[330px] order-1 md:order-0" vertical>
                                <div className="md:block hidden">
                                    <Flex
                                        style={{
                                            backgroundImage: `url(${FindResultHeader})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "100% 100%",
                                            width: "100%",
                                            color: "white",
                                            padding: "10px 0",
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                        align="center"
                                        justify="center"
                                    >
                                        <Image
                                            src={require("../../assets/images/minhngoctv/minhngoctv-find-result-gif.gif")}
                                            preview={false}
                                        />
                                        BÌNH CHỌN CẶP SỐ ĐẸP
                                    </Flex>
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-8.gif")}
                                        rootClassName="w-full mb-2"
                                        preview={false}
                                    />
                                </div>

                                <div className="md:block hidden">
                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                        lang="VIM"
                                    />
                                </div>
                                <Flex vertical gap={10} className="px-3 sm:px-0 hidden sm:flex">
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-9.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-10.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-12.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-13.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>

                                <Image
                                    src={require("../../assets/images/minhngoctv/minhngoctv-14.png")}
                                    rootClassName="w-full sm:hidden flex"
                                    preview={false}
                                />
                            </Flex>
                        </Flex>
                    </div>
                </Flex>

                <center className="text-white font-normal text-lg bg-[#5D0000] w-full py-3 mt-3">www.minhngoctv.com</center>
            </Container>
        </div>
    );
}
