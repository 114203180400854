import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { XOSODEVELOP_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
import Warper from "../../assets/images/xoso-develop/xo-sodevelop-header-2.png";
import { Helmet } from "react-helmet";
const { useBreakpoint } = Grid;

const color = "#720000";
const color_bg = "white";
const huayId = XOSODEVELOP_ID;
const hauayName = "Xoso Develop";

export default function XosodevelopPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: huayId }).unwrap();
            const result_history = await getHistoryReward({ huayId: huayId }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = hauayName;
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/xoso-develop/xo-sodevelop-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <dev
                    style={{
                        width: "100%",
                        borderTop: "15px solid #ffc60a",
                        borderBottom: "5px solid #720000",
                        marginBottom: "4px",
                    }}
                />
                {/* header logo */}
                <Flex
                    align="center"
                    style={{
                        backgroundImage: `url(${Warper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                        height: "!h-[80%]",
                    }}
                >
                    <Image src={Warper} preview={false} className="!h-[80%]" />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: `white`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        // maxWidth: 1024,
                        width: "100%",
                        borderTop: "5px solid #720000",
                        marginTop: 5,
                    }}
                >
                    <div className="pt-3">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex vertical flex={1}>
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-1.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-2.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-3.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-4.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-5.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                            <Flex className="w-full md:max-w-[600px]" vertical>
                                <FindResult
                                    date={rewardDetail?.roundName}
                                    rewardData={data}
                                    color={color}
                                    bgColor={color_bg}
                                    lang="VIM"
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-6.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />

                                {/* content result */}
                                <ResultLottery
                                    status={statusReward}
                                    data={data}
                                    date={rewardDetail?.roundName}
                                    color={color}
                                    bgColor={color_bg}
                                    style={{ border: "2px solid #720000", marginTop: 10, marginBottom: 10 }}
                                    lang="VIM"
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-7.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                {/* history */}
                                <HistoryResult data={historyData} color={color} bgColor={color_bg} lang="VIM" />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-8.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                    className="mb-2"
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-9.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                    className="mb-2"
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-10.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                    className="mb-2"
                                />
                            </Flex>
                            <Flex vertical flex={1}>
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <Divider className="bg-[#FFF] h-3 m-0" />
                    <center
                        className="py-2"
                        style={{ width: "100%", backgroundColor: "#ffc60a", borderTop: "5px solid #720000" }}
                    >
                        ©2024 xosodevelop.com, All Right Reserved.
                    </center>
                </Flex>
            </Container>
        </div>
    );
}
