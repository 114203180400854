import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { DOW_JONES_EXTRA_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const color = "#032a67";
export default function DowJonesExtraPage() {
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: DOW_JONES_EXTRA_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: DOW_JONES_EXTRA_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "DOW JONES EXTRA JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/dowjonesextra/dowjonesextra-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col pb-20"}>
                <Image
                    src={require("../../assets/images/dowjonesextra/dowjonesextra-warpper.png")}
                    preview={false}
                    rootClassName="fixed z-[-1] w-full max-w-[800px]"
                    style={{ height: "100vh", width: "100%" }}
                />

                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: "#011c53" }} />
                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/dowjonesextra/dowjonesextra-header-2.png")}
                        rootClassName="max-w-[800px]"
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex justify="center">
                    <div className="w-full max-w-[700px] pt-6">
                        <FindResult
                            date={rewardDetail?.roundName}
                            rewardData={data}
                            color={color}
                            bgColor={"#B7BCCA"}
                            lang="ENG"
                        />
                        <Image
                            src={require("../../assets/images/dowjonesextra/dowjonesextra-1.png")}
                            rootClassName="max-w-[800px]"
                            preview={false}
                        />

                        {/* content result */}
                        <ResultLottery
                            status={statusReward}
                            data={data}
                            date={rewardDetail?.roundName}
                            color={color}
                            bgColor={"#B7BCCA"}
                            lang="ENG"
                        />

                        {/* history */}
                        <div className="mt-[-24px]">
                            <HistoryResult data={historyData} color={color} bgColor={"#B7BCCA"} lang="ENG" />
                        </div>
                    </div>
                </Flex>
                <div className="absolute bottom-[8px] w-full max-w-[800px] ">
                    <Divider className="bg-[#A90B03] h-1 mb-0" />
                    <center className="text-[#032a67] font-normal text-lg py-3 bg-[#eee]">
                        Copyright ©2024 - www.dowjonesextra.com
                    </center>
                </div>
            </Container>
        </div>
    );
}
