import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal, Tabs } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
    ChartGen,
    Container,
    DayRewardPlus,
    HistoryPlusResult,
    LineChart2,
    PlusResult2,
    PlusResultValueText,
} from "../../components";
import { LOTTO_SUPPER_RICH_DEFAULT } from "../../constants/ConfigPlus";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getResultTime } from "../../utils/ConvertDate";
import { nameResult } from "../../utils/ConvertNamePlus";
import "./styles/custom.css";

const color = LOTTO_SUPPER_RICH_DEFAULT?.color;
const bgDayReward = LOTTO_SUPPER_RICH_DEFAULT?.bgReward;
const idFirst = LOTTO_SUPPER_RICH_DEFAULT?.first_id;
const idSecond = LOTTO_SUPPER_RICH_DEFAULT?.second_id;
const idThird = LOTTO_SUPPER_RICH_DEFAULT?.third_id;
const starTime = LOTTO_SUPPER_RICH_DEFAULT?.start_time;
const endTime = LOTTO_SUPPER_RICH_DEFAULT?.end_time;
const startFirstTime = LOTTO_SUPPER_RICH_DEFAULT?.start_first_time;
const startSecondTime = LOTTO_SUPPER_RICH_DEFAULT?.start_second_time;
const startThirdTime = LOTTO_SUPPER_RICH_DEFAULT?.start_third_time;
const firstName = nameResult[LOTTO_SUPPER_RICH_DEFAULT?.lang]?.firstName;
const secondName = nameResult[LOTTO_SUPPER_RICH_DEFAULT?.lang]?.secondName;
const thirdName = nameResult[LOTTO_SUPPER_RICH_DEFAULT?.lang]?.thirdName;
const closeTime = LOTTO_SUPPER_RICH_DEFAULT?.disable_time_list;

function LottoSupperRich() {
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(undefined);
    const [resultRu, setResultRu] = useState(undefined);
    const [resultGe, setResultGe] = useState(undefined);
    const [resultEn, setResultEn] = useState(undefined);
    const [resultNumber, serResultNumber] = useState("");
    const [nameLottoRu, setNameLottoRu] = useState("");
    const [nameLottoGe, setNameLottoGe] = useState("");
    const [nameLottoEn, setNameLottoEn] = useState("");
    const [statusRewardRu, setStatusRewardRu] = useState("");
    const [statusRewardGe, setStatusRewardGe] = useState("");
    const [statusRewardEn, setStatusRewardEn] = useState("");

    const [getReward, result_reward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [history, setHistory] = useState([]);
    const [historyRu, setHistoryRu] = useState([]);
    const [historyGe, setHistoryGe] = useState([]);
    const [historyEn, setHistoryEn] = useState([]);
    const [firstReward, setFirstReward] = useState("");
    const [secondReward, setSecondReward] = useState("");
    const [thirdReward, setThirdReward] = useState("");

    const [rewardRu, setRewardRu] = useState("");
    const [rewardGe, setRewardGe] = useState("");
    const [rewardEn, setRewardEn] = useState("");

    const [tabActive, setTabActive] = useState("1");

    function generateTimes(start, end, interval = 1) {
        const times = [];
        const [startHours, startMinutes] = start.split(":").map(Number);
        const [endHours, endMinutes] = end.split(":").map(Number);

        let startTimeInMinutes = startHours * 60 + startMinutes;
        const endTimeInMinutes = endHours * 60 + endMinutes;

        for (let i = startTimeInMinutes; i <= endTimeInMinutes; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            times.push(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`);
        }

        return times;
    }
    const getCloseTimeList = () => {
        let result_disable_time = [];
        const interval = 1; // interval in minutes
        closeTime.map((item) => {
            result_disable_time = [...result_disable_time, ...generateTimes(item.start, item.end, interval)];
        });
        return result_disable_time || [];
    };

    useEffect(() => {
        document.title = "Lotto Super Rich";
        onFetch();
    }, []);

    const onFetch = async () => {
        try {
            const now = getResultTime(dayjs());

            let result_number_ru = "";
            let result_number_ge = "";
            let result_number_en = "";

            const result_first = await getReward({ huayId: idFirst }).unwrap();
            const result_history_first = await getHistoryReward({ huayId: idFirst }).unwrap();

            const result_second = await getReward({ huayId: idSecond }).unwrap();
            const result_history_second = await getHistoryReward({ huayId: idSecond }).unwrap();

            const result_third = await getReward({ huayId: idThird }).unwrap();
            const result_history_third = await getHistoryReward({ huayId: idThird }).unwrap();
            if (result_first?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_first?.data?.announceAt)) {
                result_number_ru = result_first?.data?.result?.primaryNumber;
            }
            if (result_second?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_second?.data?.announceAt)) {
                result_number_ge = result_second?.data?.result?.primaryNumber;
            }
            if (result_third?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_third?.data?.announceAt)) {
                result_number_en = result_third?.data?.result?.primaryNumber;
            }
            // id,date,start,end,current,result,disable_time_list
            const _resultRu = ChartGen(
                idFirst,
                getResultTime(result_first?.data?.roundName),
                starTime,
                endTime,
                getResultTime(result_first?.data?.serverTime),
                result_number_ru,
                []
            );

            const _resulGe = ChartGen(
                idSecond,
                getResultTime(result_second?.data?.roundName),
                starTime,
                endTime,
                getResultTime(result_second?.data?.serverTime),
                result_number_ge,
                []
            );

            const _resulEn = ChartGen(
                idThird,
                getResultTime(result_third?.data?.roundName),
                starTime,
                endTime,
                getResultTime(result_third?.data?.serverTime),
                result_number_en,
                []
            );

            setResultRu(_resultRu);
            setResultGe(_resulGe);
            setResultEn(_resulEn);

            setStatusRewardRu(result_first?.data?.viewStatus);
            setStatusRewardGe(result_second?.data?.viewStatus);
            setStatusRewardEn(result_third?.data?.viewStatus);

            setHistoryRu([
                ...(result_history_first?.data?.map((item) => {
                    return {
                        ...item,
                        roundName: item?.roundName,
                    };
                }) || []),
            ]);

            setHistoryGe([
                ...(result_history_second?.data?.map((item) => {
                    return {
                        ...item,
                        roundName: item?.roundName,
                    };
                }) || []),
            ]);

            setHistoryEn([
                ...(result_history_third?.data?.map((item) => {
                    return {
                        ...item,
                        roundName: item?.roundName,
                    };
                }) || []),
            ]);

            setRewardRu(result_first?.data?.result?.primaryNumber || "");
            setRewardGe(result_second?.data?.result?.primaryNumber || "");
            setRewardEn(result_third?.data?.result?.primaryNumber || "");
            setNameLottoRu(result_first?.data?.roundName);
            setNameLottoGe(result_second?.data?.roundName);
            setNameLottoEn(result_third?.data?.roundName);
            setLoadingHistory(false);
            setLoading(false);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
            setLoadingHistory(false);
            setLoading(false);
        }
    };

    const onTabsChange = (key) => {
        setTabActive(key);
    };

    const RenderElement = ({ result, graph, statusReward, name, date, nameLotto, history, reward }) => {
        return (
            <Flex vertical className="md:mx-0 mx-[10px]">
                <Flex align="center" className="min-[700px]:flex-row flex-col">
                    {graph?.length > 0 ? (
                        <Flex className="h-[400px] w-full mt-[-50px]">
                            <LineChart2 result={graph || []} className="min-[700px]:ml-[-70px] z-10 pl-[30px]" color={color} />
                        </Flex>
                    ) : (
                        <div className="h-[400px] mr-[115px]" />
                    )}
                    <center className="w-full z-20">
                        <div
                            style={{
                                width: graph ? "auto" : 400,
                            }}
                            className="min-[700px]:w-full min-[700px]:ml-[-115px] mt-[-20px] min-[700px]:mt-0"
                        >
                            <PlusResultValueText status={statusReward} result={result} />
                        </div>
                    </center>
                </Flex>

                <Flex className="min-[500px]:mx-0 md:mt-[-30px] mx-3" style={{ zIndex: 30 }} vertical>
                    <center>
                        <div className="pb-2 max-w-[600px]">
                            {console.log(reward)}
                            <DayRewardPlus
                                firstReward={reward}
                                secondReward={""}
                                thirdReward={""}
                                // disableFirst
                                disableSecond
                                disableThird
                                color={color}
                                bgColor={bgDayReward}
                                lang={LOTTO_SUPPER_RICH_DEFAULT?.lang}
                                firstRewardText={name}
                                secondRewardText={""}
                                thirdRewardText={""}
                            />
                        </div>
                    </center>
                    <PlusResult2
                        color={color}
                        resultNumber={reward}
                        status={statusReward}
                        result={result}
                        name={nameLotto}
                        lang={LOTTO_SUPPER_RICH_DEFAULT?.lang}
                    />
                    <HistoryPlusResult color={color} data={history} lang={LOTTO_SUPPER_RICH_DEFAULT?.lang} />
                </Flex>
            </Flex>
        );
    };

    const items = [
        {
            key: "1",
            label: "RUSSIA",
            children: (
                <RenderElement
                    result={resultRu}
                    graph={resultRu?.graph}
                    statusReward={statusRewardRu}
                    name={"RUSSIA"}
                    nameLotto={nameLottoRu}
                    history={historyRu}
                    reward={rewardRu}
                />
            ),
        },
        {
            key: "2",
            label: "GERMANY",
            children: (
                <RenderElement
                    result={resultGe}
                    graph={resultGe?.graph}
                    statusReward={statusRewardGe}
                    name={"GERMANY"}
                    nameLotto={nameLottoGe}
                    history={historyGe}
                    reward={rewardGe}
                />
            ),
        },
        {
            key: "3",
            label: "ENGLISH",
            children: (
                <RenderElement
                    result={resultEn}
                    graph={resultEn?.graph}
                    statusReward={statusRewardEn}
                    name={"ENGLISH"}
                    nameLotto={nameLottoEn}
                    history={historyEn}
                    reward={rewardEn}
                />
            ),
        },
    ];

    return (
        <div style={{ background: "white" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/plus/lottosuperrich-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative flex flex-col max-w-[900px] gap-[10px]"}>
                <Flex>
                    <Image
                        src={require("../../assets/images/plus/lottosuperrich-logo.png")}
                        className="!h-[60px]"
                        preview={false}
                    />
                </Flex>
                {/* <Flex
                    style={{
                        backgroundColor: color,
                    }}
                    className="rounded-ss-lg rounded-se-lg z-20"
                    justify="flex-end"
                >
                    <Flex className={`py-1 px-4 text-white  z-20 w-full max-w-[500px]`} justify="space-between" wrap="wrap">
                        <Flex className="cursor-pointer">Home</Flex>
                        <Flex className="cursor-pointer">About Us</Flex>
                        <Flex className="cursor-pointer">Stats</Flex>
                        <Flex className="cursor-pointer min-[400px]:flex hidden">FAQ</Flex>
                        <Flex className="cursor-pointer">Contact Us</Flex>
                    </Flex>
                </Flex> */}
                <Tabs defaultActiveKey="1" items={items} onChange={onTabsChange} centered type="card" />
                {/* <RenderElement /> */}
            </Container>
        </div>
    );
}

export default LottoSupperRich;
