import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
    ChartGen,
    Container,
    DayRewardPlus,
    HistoryPlusResult,
    LineChart2,
    PlusResult2,
    PlusResultValueText,
} from "../../components";
import { KTOP_VIP_INDEX_DEFAULT } from "../../constants/ConfigPlus";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getResultTime } from "../../utils/ConvertDate";
import { nameResult } from "../../utils/ConvertNamePlus";

const color = KTOP_VIP_INDEX_DEFAULT?.color;
const bgDayReward = KTOP_VIP_INDEX_DEFAULT?.bgReward;
const idFirst = KTOP_VIP_INDEX_DEFAULT?.first_id;
const idSecond = KTOP_VIP_INDEX_DEFAULT?.second_id;
const idThird = KTOP_VIP_INDEX_DEFAULT?.third_id;
const starTime = KTOP_VIP_INDEX_DEFAULT?.start_time;
const endTime = KTOP_VIP_INDEX_DEFAULT?.end_time;
const startFirstTime = KTOP_VIP_INDEX_DEFAULT?.start_first_time;
const startSecondTime = KTOP_VIP_INDEX_DEFAULT?.start_second_time;
const startThirdTime = KTOP_VIP_INDEX_DEFAULT?.start_third_time;
const firstName = nameResult[KTOP_VIP_INDEX_DEFAULT?.lang]?.firstName;
const secondName = nameResult[KTOP_VIP_INDEX_DEFAULT?.lang]?.secondName;
const thirdName = nameResult[KTOP_VIP_INDEX_DEFAULT?.lang]?.thirdName;
const closeTime = KTOP_VIP_INDEX_DEFAULT?.disable_time_list;

function KtopVipIndexPage() {
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(undefined);
    const [resultNumber, serResultNumber] = useState("");
    const [name, setName] = useState("");

    const [statusReward, setStatusReward] = useState("");
    const [getReward, result_reward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [history, setHistory] = useState([]);
    const [firstReward, setFirstReward] = useState("");
    const [secondReward, setSecondReward] = useState("");
    const [thirdReward, setThirdReward] = useState("");

    function generateTimes(start, end, interval = 1) {
        const times = [];
        const [startHours, startMinutes] = start.split(":").map(Number);
        const [endHours, endMinutes] = end.split(":").map(Number);

        let startTimeInMinutes = startHours * 60 + startMinutes;
        const endTimeInMinutes = endHours * 60 + endMinutes;

        for (let i = startTimeInMinutes; i <= endTimeInMinutes; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            times.push(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`);
        }

        return times;
    }
    const getCloseTimeList = () => {
        let result_disable_time = [];
        const interval = 1; // interval in minutes
        closeTime.map((item) => {
            result_disable_time = [...result_disable_time, ...generateTimes(item.start, item.end, interval)];
        });
        return result_disable_time || [];
    };

    useEffect(() => {
        document.title = "Korea VIP Exchange";
        onFetch();
    }, []);

    const onFetch = async () => {
        try {
            let result_number = "";

            const result_first = await getReward({ huayId: idFirst }).unwrap();
            const result_history_first = await getHistoryReward({ huayId: idFirst }).unwrap();

            // const result_second = await getReward({ huayId: idSecond }).unwrap();
            // const result_history_second = await getHistoryReward({ huayId: idSecond }).unwrap();

            // const result_third = await getReward({ huayId: idThird }).unwrap();
            // const result_history_third = await getHistoryReward({ huayId: idThird }).unwrap();
            const disable_list = getCloseTimeList();
            const history = [
                ...(result_history_first?.data?.map((item) => {
                    return {
                        ...item,
                        roundName: item?.roundName + `(${firstName})`,
                    };
                }) || []),
                // ...(result_history_second?.data?.map((item) => {
                //     return {
                //         ...item,
                //         roundName: item?.roundName + `(${secondName})`,
                //     };
                // }) || []),
                // ...(result_history_third?.data?.map((item) => {
                //     return {
                //         ...item,
                //         roundName: item?.roundName + `(${thirdName})`,
                //     };
                // }) || []),
            ];
            const _history = history.sort(function (a, b) {
                return new Date(b.announceAt) - new Date(a.announceAt);
            });

            const now = getResultTime(dayjs());
            let name = "";
            const roundName = result_first?.data?.roundName;
            if (result_first?.data?.viewStatus === "LOADING") {
                result_number = "";
                name = `${roundName} (${firstName})`;
                setStatusReward("LOADING");
            }
            // else if (result_second?.data?.viewStatus === "LOADING") {
            //     result_number = "";
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("LOADING");
            // } else if (result_third?.data?.viewStatus === "LOADING") {
            //     result_number = "";
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("LOADING");
            // }
            else if (startFirstTime < now && result_first?.data?.viewStatus == "PREPARE") {
                result_number = "";
                name = `${roundName} (${firstName})`;
                setStatusReward("PREPARE");
            }
            //  else if (startSecondTime < now && result_second?.data?.viewStatus == "PREPARE") {
            //     result_number = "";
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("PREPARE");
            // } else if (startThirdTime < now && result_third?.data?.viewStatus == "PREPARE") {
            //     result_number = "";
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("PREPARE");
            // }
            else if (result_first?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_first?.data?.announceAt)) {
                result_number = result_first?.data?.result?.primaryNumber;
                name = `${roundName} (${firstName})`;
                setStatusReward("ANNOUNCED");
            }
            //  else if (result_second?.data?.viewStatus === "ANNOUNCED" && now < getResultTime(result_third?.data?.announceAt)) {
            //     result_number = result_second?.data?.result?.primaryNumber;
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("ANNOUNCED");
            // } else if (result_third?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_third?.data?.announceAt)) {
            //     result_number = result_third?.data?.result?.primaryNumber;
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("ANNOUNCED");
            // }

            // id,date,start,end,current,result,disable_time_list
            const _result = ChartGen(
                idFirst,
                getResultTime(result_first?.data?.roundName),
                starTime,
                endTime,
                getResultTime(result_first?.data?.serverTime),
                result_number,
                disable_list
            );

            const __result = {
                ..._result,
                graph: _result?.graph?.map((item) => {
                    if (
                        item.time === getResultTime(result_first?.data?.announceAt) &&
                        result_first?.data?.viewStatus === "ANNOUNCED"
                    ) {
                        return {
                            time: item.time,
                            value: Number(
                                String(item.value)?.slice(0, -4) +
                                    result_first?.data?.result?.primaryNumber.slice(0, 1) +
                                    "." +
                                    result_first?.data?.result?.primaryNumber.slice(1, 3)
                            ),
                        };
                    }
                    // if (
                    //     item.time === getResultTime(result_second?.data?.announceAt) &&
                    //     result_second?.data?.viewStatus === "ANNOUNCED"
                    // ) {
                    //     return {
                    //         time: item.time,
                    //         value: Number(
                    //             String(item.value)?.slice(0, -4) +
                    //                 result_second?.data?.result?.primaryNumber.slice(0, 1) +
                    //                 "." +
                    //                 result_second?.data?.result?.primaryNumber.slice(1, 3)
                    //         ),
                    //     };
                    // }
                    // if (
                    //     item.time === getResultTime(result_third?.data?.announceAt) &&
                    //     result_third?.data?.viewStatus === "ANNOUNCED"
                    // ) {
                    //     return {
                    //         time: item.time,
                    //         value: Number(
                    //             String(item.value)?.slice(0, -4) +
                    //                 result_third?.data?.result?.primaryNumber.slice(0, 1) +
                    //                 "." +
                    //                 result_third?.data?.result?.primaryNumber.slice(1, 3)
                    //         ),
                    //     };
                    // }
                    else {
                        return item;
                    }
                }),
            };

            setHistory(_history);
            setFirstReward(result_first?.data?.result?.primaryNumber || "");
            // setSecondReward(result_second?.data?.result?.primaryNumber || "");
            // setThirdReward(result_third?.data?.result?.primaryNumber || "");
            setName(name);
            setResult(__result);
            serResultNumber(result_number);
            setLoadingHistory(false);
            setLoading(false);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
            setLoadingHistory(false);
            setLoading(false);
        }
    };
    return (
        <div style={{ background: "white" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/plus/ktopvipindex-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative flex flex-col max-w-[900px] gap-[10px]"}>
                <Flex>
                    <Image
                        src={require("../../assets/images/plus/ktopvipindex-logo.png")}
                        className="!h-[60px]"
                        preview={false}
                    />
                </Flex>
                <Flex
                    style={{
                        backgroundColor: color,
                    }}
                    className="rounded-ss-lg rounded-se-lg z-20"
                    justify="flex-end"
                >
                    <Flex className={`py-1 px-4 text-white  z-20 w-full max-w-[500px]`} justify="space-between" wrap="wrap">
                        <Flex className="cursor-pointer">Home</Flex>
                        <Flex className="cursor-pointer">About Us</Flex>
                        <Flex className="cursor-pointer">Stats</Flex>
                        <Flex className="cursor-pointer min-[400px]:flex hidden">FAQ</Flex>
                        <Flex className="cursor-pointer">Contact Us</Flex>
                    </Flex>
                </Flex>
                <Flex align="center" className="mt-[-40px] min-[700px]:flex-row flex-col md:mx-0 mx-[10px]">
                    {result?.graph?.length > 0 ? (
                        <LineChart2 result={result?.graph || []} className="min-[700px]:ml-[-70px] pl-[30px]" color={color} />
                    ) : (
                        <div className="h-[400px] mr-[115px]" />
                    )}
                    <center className="w-full z-20">
                        <div
                            style={{
                                width: result?.graph?.length ? "auto" : 400,
                            }}
                            className="min-[700px]:w-full min-[700px]:ml-[-115px] mt-[-20px] min-[700px]:mt-0"
                        >
                            <PlusResultValueText status={statusReward} result={result} />
                        </div>
                    </center>
                </Flex>

                <Flex className="min-[500px]:mx-0 mx-3 md:mt-[-30px] z-20" vertical>
                    <center>
                        <div className="pb-2 max-w-[600px]">
                            <DayRewardPlus
                                firstReward={firstReward}
                                secondReward={secondReward}
                                thirdReward={thirdReward}
                                // disableFirst
                                disableSecond
                                disableThird
                                color={color}
                                bgColor={bgDayReward}
                                lang={KTOP_VIP_INDEX_DEFAULT?.lang}
                            />
                        </div>
                    </center>
                    <PlusResult2
                        color={color}
                        resultNumber={resultNumber}
                        status={statusReward}
                        result={result}
                        name={name}
                        lang={KTOP_VIP_INDEX_DEFAULT?.lang}
                        date={result_reward?.data?.data?.roundName}
                    />
                    <HistoryPlusResult color={color} data={history} lang={KTOP_VIP_INDEX_DEFAULT?.lang} />
                </Flex>
            </Container>
        </div>
    );
}

export default KtopVipIndexPage;
