import { Flex } from "antd";

export default function DayRewardPlus({
    color = "#BA0909",
    bgColor = "white",
    lang = "lo",
    style,
    disableFirst,
    disableSecond,
    disableThird,
    firstReward,
    secondReward,
    thirdReward,
    firstRewardText,
    secondRewardText,
    thirdRewardText,
}) {
    const textLang = {
        lo: {
            first: "ເຊົ້າ",
            second: "ບ່າຍ",
            third: "ແລງ",
            top: "ເທິ່ງ",
            bottom: "ລຸ່ມ",
        },
        vi: {
            first: "buổi sáng",
            second: "buổi chiều",
            third: "Buổi tối",
            top: "tầng trên",
            bottom: "thấp hơn",
        },
        en: {
            first: "morning",
            second: "afternoon",
            third: "evening",
            top: "top",
            bottom: "bottom",
        },
    };
    const firstText = firstRewardText || textLang[lang].first;
    const secondText = secondRewardText || textLang[lang].second;
    const thirdText = thirdRewardText || textLang[lang].third;
    const topText = textLang[lang].top;
    const bottomText = textLang[lang].bottom;
    return (
        <div className="px-3 sm:px-0" style={{ ...style }}>
            <div
                className="rounded-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] overflow-hidden"
                style={{
                    borderColor: color,
                    background: bgColor,
                }}
            >
                {!disableFirst && (
                    <Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{firstText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{topText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{firstReward?.slice(0, 3) || "-"}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{bottomText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{firstReward?.slice(3, 5) || "-"}</div>
                        </Flex>
                    </Flex>
                )}
                {!disableSecond && (
                    <Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{secondText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{topText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{secondReward?.slice(0, 3) || "-"}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{bottomText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{secondReward?.slice(3, 5) || "-"}</div>
                        </Flex>
                    </Flex>
                )}
                {!disableThird && (
                    <Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{thirdText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{topText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{thirdReward?.slice(0, 3) || "-"}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{bottomText}</div>
                        </Flex>
                        <Flex
                            flex={1}
                            justify="center"
                            className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                            style={{ background: bgColor, borderColor: color }}
                        >
                            <div className={`py-[7px] flex flex-1 justify-center`}>{thirdReward?.slice(3, 5) || "-"}</div>
                        </Flex>
                    </Flex>
                )}
            </div>
        </div>
    );
}
