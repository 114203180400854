import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Warper from "../../assets/images/laoextra/laoextra-header-bg.png";
import { FindResult, HistoryResult, ImageSlide, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { LAO_EXTRA_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
const { useBreakpoint } = Grid;

const color = "#b8860a";
const warper_bg = "#F8F8F8";
const color_bg = "white";
export default function LaosExtraPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: LAO_EXTRA_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: LAO_EXTRA_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "LAOS EXTRA JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/laoextra/laoextra-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex
                    align="center"
                    style={{
                        backgroundImage: `url(${Warper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Image
                        src={require("../../assets/images/laoextra/laoextra-header-2.png")}
                        preview={false}
                        className="!h-[80%]"
                    />
                </Flex>
                <Divider className="bg-[#B00000] h-[20px] m-0" />

                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: `white`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                    }}
                >
                    <div className="pt-3">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="w-full md:max-w-[600px]" vertical>
                                <ImageSlide
                                    data={[
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-1.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-2.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-3.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-4.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-1.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-2.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-3.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-4.png"),
                                        },
                                    ]}
                                    className={"!mb-0 md:!mb-3"}
                                />

                                <FindResult date={rewardDetail?.roundName} rewardData={data} color={color} bgColor={color_bg} />
                                <Image
                                    src={require("../../assets/images/laoextra/laoextra-2.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />

                                {/* content result */}
                                <ResultLottery
                                    status={statusReward}
                                    data={data}
                                    date={rewardDetail?.roundName}
                                    color={color}
                                    bgColor={color_bg}
                                />
                                <Image
                                    src={require("../../assets/images/laoextra/laoextra-3.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                {/* history */}
                                <HistoryResult data={historyData} color={color} bgColor={color_bg} />
                            </Flex>
                            <Flex vertical flex={1}>
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra-5.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra-6.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra-7.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <Image
                        src={require("../../assets/images/laoextra/laoextra-4.png")}
                        rootClassName="w-full"
                        className="mt-5"
                        preview={false}
                    />
                    <Divider className="bg-[#FFF] h-3 m-0" />

                    <center className="text-white font-normal text-lg py-5 bg-[#011e3a]">Copyright ©2024 - laoextra.com</center>
                </Flex>
            </Container>
        </div>
    );
}
