import { LoadingOutlined } from "@ant-design/icons";
import { Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { LAD_RED_CROSS_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

export default function LaoRedCrossPage() {
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: LAD_RED_CROSS_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: LAD_RED_CROSS_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            // setStatusReward("ANNOUNCED");
            // const _data = generateData("11122");
            // const _data = generateData("12345");
            // setData(_data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "ລາວກາຊາດ JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/lao-cross-red/lao-cross-head-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">ກຳລັງໂຫຼດ ...</div>
                </div>
            )}
            <div className="border-b-[8px] border-solid border-[#00f]" />
            <Container>
                <div className="relative">
                    <div className="absolute top-0 z-10 flex items-center h-full left-5">
                        <Image
                            src={require("../../assets/images/lao-cross-red/lao-cross-head-logo-2.png")}
                            preview={false}
                            className="max-w-[375px] max-h-[100px] !h-full"
                            rootClassName="!h-[70%]"
                        />
                    </div>
                    <Image src={require("../../assets/images/lao-cross-red/lao-cross-head-bg.png")} preview={false} />
                </div>
                <div className="h-[5px] border-b-[5px] border-solid border-[#ff0000] mb-2" />
                <div className="flex flex-col sm:px-10 md:flex-row md:gap-3 md:p-0">
                    <div className="w-full md:max-w-[450px]">
                        <Image
                            src={require("../../assets/images/lao-cross-red/lao-cross-1.png")}
                            preview={false}
                            rootClassName="w-full md:min-w-[450px]"
                        />
                        <FindResult date={rewardDetail?.roundName} rewardData={data} />
                        {/* <center>
                                <div className="text-[1.3rem] font-extralight">ງວດປະຈຳວັນທີ່ -</div>
                                <div className="text-[4rem] font-bold tracking-[10px]">-----</div>
                                <div className="text-[1.2rem] font-extralight">ເວລາອອກເລກ --</div>
                            </center>
                            <div className="mx-1 border-[#dee2e6] border-solid border-t-[2px] pb-1 mt-2">
                                {numberList?.map((number) => {
                                    return (
                                        <div className="flex">
                                            <div className="text-[1.8rem] w-[60%] flex justify-center font-base font-light border-[#dee2e6] border-solid border-b-[2px] border-l-[2px] border-r-[2px]">
                                                {number.name}
                                            </div>
                                            <div className="text-[1.8rem] w-[40%] flex justify-center font-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px]">
                                                {number.result ? (
                                                    <div>{number.result}</div>
                                                ) : (
                                                    Array.from(Array(number.length).keys()).map(() => <div>-</div>)
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div> */}
                        <div className="hidden md:block">
                            <Image
                                src={require("../../assets/images/lao-cross-red/lao-cross-3-1.png")}
                                preview={false}
                                rootClassName="!w-full"
                            />
                            <Image src={require("../../assets/images/lao-cross-red/lao-cross-3.png")} preview={false} />
                        </div>
                        {/* <div className="block md:hidden">
                            <Image src={require("../../assets/images/lao-cross-red/lao-cross-2.png")} preview={false} />
                            <div className="border-[#ff0000] border-solid border-[3px] flex flex-col mt-2 items-center">
                                <div className="text-[1.3rem]">ຜົນການອອກເລກລາງວັນ</div>
                            </div>
                            <div className="border-[#dee2e6] border-solid border-t-[2px] mt-2 flex">
                                <div className="flex w-[16%] border-x-[2px] font-base font-light border-[#dee2e6] border-solid justify-center border-b-[2px]">
                                    ງວດວັນທີ່
                                </div>
                                {numberList?.map((number) => {
                                    return (
                                        <div className="w-[14%] flex justify-center font-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px]">
                                            <center>{number.name}</center>
                                        </div>
                                    );
                                })}
                            </div>
                            {resultLottery.map((result) => {
                                return (
                                    <div className="flex">
                                        <div className="w-[16%] flex break-all justify-center text-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px] border-l-[2px]">
                                            <center>{result.date}</center>
                                        </div>
                                        {result.result.map((itemResult) => (
                                            <div className="w-[14%] flex justify-center font-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px]">
                                                <center>{itemResult.number}</center>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div> */}
                    </div>
                    <div>
                        {/* <div className="hidden md:block"> */}
                        <ResultLottery status={statusReward} data={data} date={rewardDetail?.roundName} />
                        <Image src={require("../../assets/images/lao-cross-red/lao-cross-2.png")} preview={false} />
                        <HistoryResult data={historyData} />
                        {/* <div className="border-[#ff0000] border-solid border-[3px] flex flex-col mt-2 items-center">
                            <div className="text-[1.3rem]">ຜົນການອອກເລກລາງວັນ</div>
                        </div>
                        <div className="border-[#dee2e6] border-solid border-t-[2px] mt-2 flex">
                            <div className="flex flex-1 min-w-[85px] border-x-[2px] font-base font-light border-[#dee2e6] border-solid justify-center border-b-[2px]">
                                ງວດວັນທີ່
                            </div>
                            {numberList?.map((number) => {
                                return (
                                    <div className="flex-1 flex justify-center font-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px]">
                                        <center>{number.name}</center>
                                    </div>
                                );
                            })}
                        </div>
                        {resultLottery.map((result) => {
                            return (
                                <div className="flex">
                                    <div className="flex-1 flex justify-center min-w-[85px] text-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px] border-l-[2px]">
                                        <center>{result.date}</center>
                                    </div>
                                    {result.result.map((itemResult) => (
                                        <div className="flex-1 flex justify-center font-base font-light border-[#dee2e6] border-solid border-b-[2px] border-r-[2px]">
                                            <center>{itemResult.number}</center>
                                        </div>
                                    ))}
                                </div>
                            );
                        })} */}
                    </div>
                    <div className="block md:hidden">
                        <center className="py-5">
                            <Image src={require("../../assets/images/lao-cross-red/lao-cross-4.png")} preview={false} />
                        </center>
                        <center>
                            <Image src={require("../../assets/images/lao-cross-red/lao-cross-5.png")} preview={false} />
                        </center>
                        <center>
                            <Image src={require("../../assets/images/lao-cross-red/lao-cross-6.png")} preview={false} />
                        </center>
                    </div>
                </div>
                <div className="hidden gap-3 py-5 mt-3 md:flex">
                    <div className="flex justify-center flex-1">
                        <Image src={require("../../assets/images/lao-cross-red/lao-cross-4.png")} preview={false} />
                    </div>
                    <div className="flex justify-center flex-1">
                        <Image src={require("../../assets/images/lao-cross-red/lao-cross-5.png")} preview={false} />
                    </div>
                    <div className="flex justify-center flex-1">
                        <Image src={require("../../assets/images/lao-cross-red/lao-cross-6.png")} preview={false} />
                    </div>
                </div>
            </Container>
            <div className="h-[5px] border-b-[5px] border-solid border-[#ff0000] mb-1" />
            <center className="bg-[#00f] text-[#FFF] font-light py-3">Copyright 2024 ©lao-redcross</center>
        </div>
    );
}
