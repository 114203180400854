import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { XO_SO_UNION_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
import { Helmet } from "react-helmet";

const { useBreakpoint } = Grid;

const color = "#e80000";
const warper_bg = "#d6d6d6";
const color_bg = "white";
export default function XoSoRedCrossPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: XO_SO_UNION_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: XO_SO_UNION_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "Xổ số Union";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/xosounion/xosounion-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col "}>
                <Divider className="bg-[#e5e533] h-[15px] m-0" />
                <Divider className="bg-[#e50000] h-[5px] m-0 mb-1" />
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Image src={require("../../assets/images/xosounion/xosounion-header-2.png")} preview={false} />
                <Divider className="bg-[#e50000] h-[5px] m-0" />
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "white",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                        paddingBottom: 10,
                    }}
                >
                    <div className="pt-3">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="hidden md:block md:w-full md:max-w-[200px] order-1 md:order-0" vertical gap={10}>
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-1.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-2.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-3.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-4.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-5.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                            </Flex>
                            <Flex className="w-full order-0 md:order-1 sm:mx-0" vertical>
                                <Flex vertical gap={10} className="mb-3 px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-6.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image src={require("../../assets/images/xosounion/xosounion-7.png")} preview={false} />
                                </Flex>
                                <div className="md:hidden block">
                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                        lang="VIM"
                                    />
                                </div>

                                {/* content result */}
                                <ResultLottery
                                    status={statusReward}
                                    data={data}
                                    date={rewardDetail?.roundName}
                                    color={color}
                                    bgColor={color_bg}
                                    lang="VIM"
                                />
                                {/* history */}
                                <div className="my-0">
                                    <HistoryResult data={historyData} color={color} bgColor={color_bg} lang="VIM" />
                                </div>
                                <div className="mt-[-10px] mb-2 px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-8-1.png")}
                                        rootClassName="w-full px-3 sm:px-0"
                                        preview={false}
                                    />
                                </div>

                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-8.png")}
                                    rootClassName="w-full px-3 sm:px-0"
                                    preview={false}
                                />

                                <Flex vertical gap={10} className="px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-9.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-10.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>

                            <Flex className="w-full md:max-w-[330px] order-1 md:order-0" vertical>
                                <div className="md:block hidden">
                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                        lang="VIM"
                                    />
                                </div>
                                <Flex vertical gap={10} className="px-3 sm:px-0 hidden md:flex">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-9.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-10.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-12.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-13.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-14.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
                <Divider className="bg-[#e50000] h-[5px] m-0" />
                <center className="text-black font-normal text-base bg-[#e5e533] w-full py-3">
                    ©2021 xosounion.com, All Right Reserved.
                </center>
            </Container>
        </div>
    );
}
