import { createBrowserRouter } from "react-router-dom";
import {
    DowJonesExtraOldPage,
    DowJonesExtraPage,
    DowJonesMidnightOldPage,
    DowJonesMidnightPage,
    DowJonesPowerBallPage,
    DowJonesStarOldPage,
    DowJonesStarPage,
    DowJonesTvOldPage,
    DowJonesTvPage,
    HanoiAseanOldPage,
    HanoiAseanPage,
    HanoiExtraOldPage,
    HanoiExtraPage,
    HengSengVipPage,
    HomePage,
    IndiaVipPage,
    IndiaVipPlusPage,
    KtopVipIndexPage,
    LaoCitizenOldPage,
    LaoCitizenPage,
    LaoHdOldPage,
    LaoHdPage,
    LaoPaTuXayOldPage,
    LaoPaTuXayPage,
    LaoRedCrossPage,
    LaoSanTiPapOldPage,
    LaoSanTiPapPage,
    LaoSaSeanOldPage,
    LaoSaSeanPage,
    LaosExtraOldPage,
    LaosExtraPage,
    LaoStarOldPage,
    LaoStarPage,
    LaoStarVipOldPage,
    LaoStarVipPage,
    LaoTvOldPage,
    LaoTvPage,
    LaoUnionOldPage,
    LaoUnionPage,
    LaoUnionVipOldPage,
    LaoUnionVipPage,
    LottoSupperRichPage,
    LsxVipPage,
    MinhngocPage,
    MinhngocStarPage,
    MinhngocTvPage,
    NikkeiVipStockPage,
    SgxVipPage,
    ShenZhenIndexPage,
    TsecVipIndexPage,
    VnIndexVipPage,
    XosodevelopOldPage,
    XosodevelopPage,
    XoSoHdOldPage,
    XoSoHdPage,
    XoSoRedCrossOldPage,
    XoSoRedCrossPage,
    XoSoUnionOldPage,
    XoSoUnionPage,
} from "../pages";

import LaoRedCrossOldPage from "../pages/LaoRedCrossPage/LaoRedCrossOldPage";
import VnIndexVip2Page from "../pages/PlusPage/VnIndexVip2Page";
import Layout from "./Layout";

const Router = createBrowserRouter([
    {
        path: "/home",
        element: <HomePage />,
    },
    {
        path: "/",
        element: <Layout />,
    },
    {
        path: "/lao-red-cross",
        element: <LaoRedCrossPage />,
    },
    {
        path: "/hanoi-extra",
        element: <HanoiExtraPage />,
    },
    {
        path: "/lao-star-vip",
        element: <LaoStarVipPage />,
    },
    {
        path: "/lao-union-vip",
        element: <LaoUnionVipPage />,
    },
    {
        path: "/lao-sa-sean",
        element: <LaoSaSeanPage />,
    },
    {
        path: "/lao-union",
        element: <LaoUnionPage />,
    },
    {
        path: "/dow-jones-star",
        element: <DowJonesStarPage />,
    },
    {
        path: "/dow-jones-midnight",
        element: <DowJonesMidnightPage />,
    },
    {
        path: "/dow-jones-extra",
        element: <DowJonesExtraPage />,
    },
    {
        path: "/tv-dow-jones",
        element: <DowJonesTvPage />,
    },
    {
        path: "/lao-pa-tu-xay",
        element: <LaoPaTuXayPage />,
    },
    {
        path: "/lao-san-ti-pap",
        element: <LaoSanTiPapPage />,
    },
    {
        path: "/lao-citizen",
        element: <LaoCitizenPage />,
    },
    {
        path: "/laos-extra",
        element: <LaosExtraPage />,
    },
    {
        path: "/xosodevelop",
        element: <XosodevelopPage />,
    },
    {
        path: "/hanoi-asean",
        element: <HanoiAseanPage />,
    },
    {
        path: "/lao-tv",
        element: <LaoTvPage />,
    },
    {
        path: "/xo-so-hd",
        element: <XoSoHdPage />,
    },
    {
        path: "/lao-hd",
        element: <LaoHdPage />,
    },
    {
        path: "/minhngoc-tv",
        element: <MinhngocTvPage />,
    },
    {
        path: "/lao-star",
        element: <LaoStarPage />,
    },
    {
        path: "/xo-so-red-cross",
        element: <XoSoRedCrossPage />,
    },
    {
        path: "/xo-so-union",
        element: <XoSoUnionPage />,
    },
    {
        path: "/minhngoc-star",
        element: <MinhngocStarPage />,
    },
    {
        path: "/minhngoc",
        element: <MinhngocPage />,
    },

    // old
    {
        path: "/dow-jones-star-old",
        element: <DowJonesStarOldPage />,
    },
    {
        path: "/dow-jones-midnight-old",
        element: <DowJonesMidnightOldPage />,
    },
    {
        path: "/dow-jones-extra-old",
        element: <DowJonesExtraOldPage />,
    },
    {
        path: "/tv-dow-jones-old",
        element: <DowJonesTvOldPage />,
    },
    {
        path: "/hanoi-extra-old",
        element: <HanoiExtraOldPage />,
    },
    {
        path: "/lao-red-cross-old",
        element: <LaoRedCrossOldPage />,
    },
    {
        path: "/lao-union-old",
        element: <LaoUnionOldPage />,
    },
    {
        path: "/lao-union-vip-old",
        element: <LaoUnionVipOldPage />,
    },
    {
        path: "/lao-sa-sean-old",
        element: <LaoSaSeanOldPage />,
    },
    {
        path: "/xosodevelop-old",
        element: <XosodevelopOldPage />,
    },
    {
        path: "/xo-so-union-old",
        element: <XoSoUnionOldPage />,
    },
    {
        path: "/xo-so-red-cross-old",
        element: <XoSoRedCrossOldPage />,
    },
    {
        path: "/xo-so-hd-old",
        element: <XoSoHdOldPage />,
    },
    {
        path: "/lao-star-old",
        element: <LaoStarOldPage />,
    },
    {
        path: "/lao-hd-old",
        element: <LaoHdOldPage />,
    },
    {
        path: "/lao-tv-old",
        element: <LaoTvOldPage />,
    },
    {
        path: "/lao-star-vip-old",
        element: <LaoStarVipOldPage />,
    },
    {
        path: "/hanoi-asean-old",
        element: <HanoiAseanOldPage />,
    },
    {
        path: "/laos-extra-old",
        element: <LaosExtraOldPage />,
    },
    {
        path: "/lao-citizen-old",
        element: <LaoCitizenOldPage />,
    },
    {
        path: "/lao-san-ti-pap-old",
        element: <LaoSanTiPapOldPage />,
    },
    {
        path: "/lao-pa-tu-xay-old",
        element: <LaoPaTuXayOldPage />,
    },
    {
        path: "/nv-index-vip",
        element: <VnIndexVipPage />,
    },
    {
        path: "/nv-index-vip2",
        element: <VnIndexVip2Page />,
    },

    {
        path: "/lotto-supper-rich",
        element: <LottoSupperRichPage />,
    },
    {
        path: "/lsx-vip",
        element: <LsxVipPage />,
    },
    {
        path: "/dow-jones-power-ball",
        element: <DowJonesPowerBallPage />,
    },
    {
        path: "/heng-seng-vip",
        element: <HengSengVipPage />,
    },
    {
        path: "/nikkei-vip-stock",
        element: <NikkeiVipStockPage />,
    },
    {
        path: "/tsec-vip-index",
        element: <TsecVipIndexPage />,
    },
    {
        path: "/shen-zhen-index",
        element: <ShenZhenIndexPage />,
    },
    {
        path: "/sgx-vip",
        element: <SgxVipPage />,
    },
    {
        path: "/kt-vip-index",
        element: <KtopVipIndexPage />,
    },
    {
        path: "/india-vip",
        element: <IndiaVipPage />,
    },
    {
        path: "/india-vip-plus",
        element: <IndiaVipPlusPage />,
    },
]);

export default Router;
